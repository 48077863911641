<template>
  <layout :title="$route.name">
    <div class="expande-horizontal wrap">
      <v-flex xs12>
        <v-tabs grow background-color="#f2f2f2" color="black" v-model="tab">
          <v-tab class="black--text fonte-bold"
            >Diretos ({{ getUnilevels.level_1.length }})</v-tab
          >
          <v-tab class="black--text fonte-bold"
            >Nivel 2 ({{ getUnilevels.level_2.length }})</v-tab
          >
          <v-tab class="black--text fonte-bold"
            >Nivel 3 ({{ getUnilevels.level_3.length }})</v-tab
          >
          <v-tab class="black--text fonte-bold"
            >Nivel 4 ({{ getUnilevels.level_4.length }})</v-tab
          >
        </v-tabs>
      </v-flex>

      <v-flex v-if="tab === 0" xs12 class="pa-1">
        <v-simple-table
          v-show="getUnilevels.level_1.length > 0"
          dense
          style="width: 100%;"
          v-if="$vuetify.breakpoint.lgAndUp"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th style="width: 20px;">
                  <v-icon color="#333">mdi-account-group-outline</v-icon>
                </th>
                <th class="fonte-bold black--text" style="font-size: 12pt;">
                  NOME
                </th>
                <!-- <th class="fonte">TELEFONE</th> -->
                <th class="fonte-bold black--text" style="font-size: 12pt;">
                  LOGIN
                </th>
                <th class="fonte-bold black--text" style="font-size: 12pt;">
                  ATIVO
                </th>
                <th class="fonte-bold black--text" style="font-size: 12pt;">
                  DATA DE INGRESSO
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in getUnilevels.level_1" :key="item._id">
                <td style="width: 20px;">
                  <v-icon color="#333">mdi-account-group-outline</v-icon>
                </td>
                <td class="font-weight-bold fonte">
                  {{ item.indicated_name }}
                </td>
                <!-- <td class="font-weight-bold fonte">{{ item.indicated_phone }}</td> -->
                <td class="font-weight-bold fonte">
                  {{ item.indicated_login }}
                </td>
                <td class="font-weight-bold fonte">
                  {{ item.active ? "sim" : "não" }}
                </td>
                <td class="font-weight-bold fonte">
                  {{ $moment(item.created_at).format("DD/MM/YYYY [às] HH:mm") }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-list class="pa-0" color="transparent" v-else>
          <template v-for="(item, index) in getUnilevels.level_1">
            <v-list-item :key="item._id">
              <v-avatar>
                <v-icon>mdi-account-circle-outline</v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <span class="fonte-bold">
                    {{ item.indicated_name }}
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.active ? "Ativo" : "Inativo" }}
                </v-list-item-subtitle>
                <!-- <v-list-item-subtitle> {{ item.indicated_phone }} </v-list-item-subtitle> -->
                <v-list-item-subtitle class="fonte">
                  Desde
                  {{ $moment(item.created_at).format("DD/MM/YYYY [às] HH:mm") }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="index"></v-divider>
          </template>
        </v-list>
        <Empty v-if="getUnilevels.level_1.length === 0"></Empty>
      </v-flex>

      <v-flex v-if="tab === 1" xs12 class="pa-1">
        <v-simple-table
          v-show="getUnilevels.level_2.length > 0"
          dense
          style="width: 100%;"
          v-if="$vuetify.breakpoint.lgAndUp"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th style="width: 20px;">
                  <v-icon color="#333">mdi-account-group-outline</v-icon>
                </th>
                <th class="fonte-bold black--text" style="font-size: 12pt;">
                  NOME
                </th>
                <!-- <th class="fonte">TELEFONE</th> -->
                <th class="fonte-bold black--text" style="font-size: 12pt;">
                  LOGIN
                </th>
                <th class="fonte-bold black--text" style="font-size: 12pt;">
                  ATIVO
                </th>
                <th class="fonte-bold black--text" style="font-size: 12pt;">
                  DATA DE INGRESSO
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in getUnilevels.level_2" :key="item._id">
                <td style="width: 20px;">
                  <v-icon color="#333">mdi-account-group-outline</v-icon>
                </td>
                <td class="font-weight-bold fonte">
                  {{ item.indicated_name }}
                </td>
                <!-- <td class="font-weight-bold fonte">{{ item.indicated_phone }}</td> -->
                <td class="font-weight-bold fonte">
                  {{ item.indicated_login }}
                </td>
                <td class="font-weight-bold fonte">
                  {{ item.active ? "sim" : "não" }}
                </td>
                <td class="font-weight-bold fonte">
                  {{ $moment(item.created_at).format("DD/MM/YYYY [às] HH:mm") }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-list class="pa-0" color="transparent" v-else>
          <template v-for="(item, index) in getUnilevels.level_2">
            <v-list-item :key="item._id">
              <v-avatar>
                <v-icon>mdi-account-circle-outline</v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <span class="fonte-bold">
                    {{ item.indicated_name }}
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.active ? "Ativo" : "Inativo" }}
                </v-list-item-subtitle>
                <!-- <v-list-item-subtitle> {{ item.indicated_phone }} </v-list-item-subtitle> -->
                <v-list-item-subtitle class="fonte">
                  Desde
                  {{ $moment(item.created_at).format("DD/MM/YYYY [às] HH:mm") }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="index"></v-divider>
          </template>
        </v-list>
        <Empty v-if="getUnilevels.level_2.length === 0"></Empty>
      </v-flex>

      <v-flex v-if="tab === 2" xs12 class="pa-1">
        <v-simple-table
          v-show="getUnilevels.level_3.length > 0"
          dense
          style="width: 100%;"
          v-if="$vuetify.breakpoint.lgAndUp"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th style="width: 20px;">
                  <v-icon color="#333">mdi-account-group-outline</v-icon>
                </th>
                <th class="fonte-bold black--text" style="font-size: 12pt;">
                  NOME
                </th>
                <!-- <th class="fonte">TELEFONE</th> -->
                <th class="fonte-bold black--text" style="font-size: 12pt;">
                  LOGIN
                </th>
                <th class="fonte-bold black--text" style="font-size: 12pt;">
                  ATIVO
                </th>
                <th class="fonte-bold black--text" style="font-size: 12pt;">
                  DATA DE INGRESSO
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in getUnilevels.level_3" :key="item._id">
                <td style="width: 20px;">
                  <v-icon color="#333">mdi-account-group-outline</v-icon>
                </td>
                <td class="font-weight-bold fonte">
                  {{ item.indicated_name }}
                </td>
                <!-- <td class="font-weight-bold fonte">{{ item.indicated_phone }}</td> -->
                <td class="font-weight-bold fonte">
                  {{ item.indicated_login }}
                </td>
                <td class="font-weight-bold fonte">
                  {{ item.active ? "sim" : "não" }}
                </td>
                <td class="font-weight-bold fonte">
                  {{ $moment(item.created_at).format("DD/MM/YYYY [às] HH:mm") }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-list class="pa-0" color="transparent" v-else>
          <template v-for="(item, index) in getUnilevels.level_3">
            <v-list-item :key="item._id">
              <v-avatar>
                <v-icon>mdi-account-circle-outline</v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <span class="fonte-bold">
                    {{ item.indicated_name }}
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.active ? "Ativo" : "Inativo" }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="fonte">
                  Desde
                  {{ $moment(item.created_at).format("DD/MM/YYYY [às] HH:mm") }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="index"></v-divider>
          </template>
        </v-list>
        <Empty v-if="getUnilevels.level_1.length === 0"></Empty>
      </v-flex>

      <v-flex v-if="tab === 3" xs12 class="pa-1">
        <v-simple-table
          v-show="getUnilevels.level_4.length > 0"
          dense
          style="width: 100%;"
          v-if="$vuetify.breakpoint.lgAndUp"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th style="width: 20px;">
                  <v-icon color="#333">mdi-account-group-outline</v-icon>
                </th>
                <th class="fonte-bold black--text" style="font-size: 12pt;">
                  NOME
                </th>
                <!-- <th class="fonte">TELEFONE</th> -->
                <th class="fonte-bold black--text" style="font-size: 12pt;">
                  LOGIN
                </th>
                <th class="fonte-bold black--text" style="font-size: 12pt;">
                  ATIVO
                </th>
                <th class="fonte-bold black--text" style="font-size: 12pt;">
                  DATA DE INGRESSO
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in getUnilevels.level_4" :key="item._id">
                <td style="width: 20px;">
                  <v-icon color="#333">mdi-account-group-outline</v-icon>
                </td>
                <td class="font-weight-bold fonte">
                  {{ item.indicated_name }}
                </td>
                <!-- <td class="font-weight-bold fonte">{{ item.indicated_phone }}</td> -->
                <td class="font-weight-bold fonte">
                  {{ item.indicated_login }}
                </td>
                <td class="font-weight-bold fonte">
                  {{ item.active ? "sim" : "não" }}
                </td>
                <td class="font-weight-bold fonte">
                  {{ $moment(item.created_at).format("DD/MM/YYYY [às] HH:mm") }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-list class="pa-0" color="transparent" v-else>
          <template v-for="(item, index) in getUnilevels.level_4">
            <v-list-item :key="item._id">
              <v-avatar>
                <v-icon>mdi-account-circle-outline</v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <span class="fonte-bold">
                    {{ item.indicated_name }}
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.active ? "Ativo" : "Inativo" }}
                </v-list-item-subtitle>
                <!-- <v-list-item-subtitle> {{ item.indicated_phone }} </v-list-item-subtitle> -->
                <v-list-item-subtitle class="fonte">
                  Desde
                  {{ $moment(item.created_at).format("DD/MM/YYYY [às] HH:mm") }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="index"></v-divider>
          </template>
        </v-list>
        <Empty v-if="getUnilevels.level_4.length === 0"></Empty>
      </v-flex>
    </div>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      tab: 0
    };
  },
  computed: {
    ...mapGetters(["getUnilevels"])
  },
  methods: {
    ...mapActions(["listUnilevels"])
  },
  created() {
    this.listUnilevels();
  }
};
</script>
